
function Collage() {

    return (
      <div>
        <div className="collage">
            <div className="spacer2"></div>
        </div>
      </div>
  
    );
  }
  
  export default Collage;
  